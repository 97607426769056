<template>
  <div class="register-success">
    <div class="panel">
      <div class="heading-accent text-center">
        {{ $t('auth.registerSuccess.title') }}
      </div>
      <div class="description">
        {{ $t('auth.registerSuccess.message') }}
      </div>
      <base-button class="full-width"
                   @click="$router.push({ name: 'login' })">
        {{ $t('auth.registerSuccess.signIn') }}
      </base-button>
    </div>
  </div>
</template>

<script>
  import hasBackgroundMixin from '@/services/mixins/hasBackgroundMixin.js'

  export default {
    mixins: [hasBackgroundMixin]
  }
</script>

<style lang="scss">
  .register-success {
    max-width: 400px;
    padding-top: 20px;
    margin: 0 auto;

    .panel {
      display: flex;
      flex-direction: column;

      padding: 20px 20px 20px 30px;
      margin: 0 20px;

      background: #FFFFFF;
      box-shadow: 0 6px 12px 0 rgba(54,76,106,0.20);
      border-radius: 8px;
    }

    .description {
      font-size: 14px;
      color: #797979;
      margin-bottom: 30px;
      text-align: center;
    }
  }
</style>
